.header {
    width: 100%;
    background-color: white;
    border-bottom: 1px solid black;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    .container {
        max-width: 1200px;
        padding: 0 20px;
        margin: auto;
        height: 100%;

        .list {
            height: 100%;
            display: flex;
            align-items: center;
            list-style: none;
            gap: 5px;
            justify-content: space-between;

            li {
                font-weight: 600;
                font-size: 22px;
                cursor: pointer;
                position: relative;

                a {
                    color: black;
                    text-decoration: none;
                }

                &:hover {
                    border-bottom: 2px solid rgb(229, 245, 6);
                }
            }

            .img {
                cursor: default;

                &:hover {
                    border-bottom: 0 solid rgb(229, 245, 6);
                }

                img {
                    width: 60px;
                    height: auto;
                }
            }
        }
    }
}


@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {
    .header {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .header {
        .container {
            .list {

                li {
                    font-size: 20px;
                }

                .img {
                    img {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
    }
}