.aboutMe {
    .container {
        width: 100%;
        max-width: 1200px;
        padding: 120px 20px 80px;
        margin: auto;
        display: flex;
        gap: 30px;
        position: relative;
        height: auto;

        .blockAboutMe {
            width: 100%;
            max-width: calc(100% - 400px);
            display: flex;
            flex-direction: column;
            gap: 30px;

            .blockText {
                width: 100%;
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 10px;
                overflow: hidden;
                color: white;

                .title {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                    font-size: 20px;
                    font-weight: 500;
                    background: rgba(255, 255, 255, 0.1);

                }

                .descr {
                    padding: 20px 30px;
                    font-size: 18px;
                }
            }
        }

        .blockImgMe {
            width: 100%;
            max-width: 400px;
            height: calc(650px);
            border-radius: 10px;
            padding: 30px;
            background-color: rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            flex-direction: column;
            position: sticky;
            top: 120px;

            .img {
                width: 250px;
                height: 250px;
                object-fit: cover;
                border-radius: 50%;
                border: 2px solid white;
                margin-bottom: 30px;
            }

            .name {
                text-align: center;
                width: 100%;
                padding: 0 20px 16px;
                color: white;
                font-size: 40px;
                border-bottom: 2px solid white;
                margin-bottom: 16px;
            }

            .descr {
                text-align: center;
                color: white;
                font-size: 23px;
                margin-bottom: 16px;
            }

            .btn {
                width: 100%;
                height: 48px;
                background: rgba(255, 255, 255, 0.1);
                border: 0;
                font-size: 20px;
                font-weight: 500;
                color: white;
                border: 2px solid white;
                border-radius: 10px;
                cursor: pointer;
                text-decoration: none;
                display: grid;
                place-items: center;
                transition: 0.3s;

                &:hover {
                    transform: scale(0.98);
                }
            }

            .call {
                margin-top: 20px;
                display: flex;
                font-size: 20px;
                font-weight: 600;
                text-decoration: none;
                color: white;
                gap: 8px;
                align-items: center;
            }
        }
    }
}


@media (max-width: 991.98px) {
    .aboutMe {
        .container {
            display: flex;
            align-items: center;
            flex-direction: column-reverse;

            .blockAboutMe {
                width: 100%;
                max-width: 100%;

                .blockText {
                    width: 100%;
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 10px;
                    overflow: hidden;
                    color: white;

                    .title {
                        padding-left: 20px;
                    }

                    .descr {
                        padding: 20px;
                    }
                }
            }

            .blockImgMe {
                height: auto;
                padding: 20px;
                position: static;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .aboutMe {
        .container {
            padding: 40px 20px 80px;
        }
    }
}

@media (max-width: 575.98px) {
    .aboutMe {
        .container {
            .blockAboutMe {
                .blockText {
                    .title {
                        font-size: 19px;
                        padding: 15px;
                    }

                    .descr {
                        padding: 15px;
                        font-size: 17px;
                    }
                }
            }

            .blockImgMe {
                .img {
                    width: 200px;
                    height: 200px;
                    margin-bottom: 10px;
                }

                .name {
                    font-size: 30px;
                }

                .descr {
                    color: white;
                    font-size: 19px;
                }

                .btn {
                    height: 40px;
                    font-size: 19px;
                }

                .call {
                    margin-top: 10px;
                    font-size: 19px;
                }
            }
        }
    }
}