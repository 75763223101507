.myInst {
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-top: 8px solid gold;
    border-bottom: 8px solid gold;

    .container {
        width: 100%;
        max-width: 1200px;
        padding: 40px 20px 80px;
        margin: auto;
        gap: 30px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 40px;
            text-align: center;
            width: fit-content;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                bottom: -1px;
                left: 50%;
                width: 0%;
                height: 2px;
                background-color: gold;
                animation: anim 4s ease-in infinite;
            }

            @keyframes anim {
                0% {
                    left: 50%;
                }

                100% {
                    width: 100%;
                    left: 0;
                }
            }
        }

        .blocks {
            margin-top: 60px;
            display: flex;
            overflow-x: scroll;
            gap: 10px;
            scrollbar-width: thin;
            padding-bottom: 10px;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: rga(255, 255, 255);
                border-radius: 10px;

            }

            &::-webkit-scrollbar-thumb {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: gold;
                border-radius: 10px;
            }

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
            }

        }

        .btn {
            display: grid;
            place-items: center;
            padding: 10px 30px;
            border: 2px solid black;
            color: black;
            font-size: 20px;
            background: transparent;
            font-weight: 600;
            transition: 0.5s;
            margin-top: 40px;
            text-decoration: none;

            &:hover {
                cursor: pointer;
                transform: translateY(-5px);
            }
        }
    }
}


// @media (max-width: 991.98px) {
//     .myInst {
//         .container {
//             .title {
//                 font-size: 30px;
//             }

//             .blocks {
//                 grid-template-columns: 1fr 1fr;
//                 gap: 20px;

//                 img {
//                     height: 500px;
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 575.98px) {
//     .myInst {
//         .container {
//             .title {
//                 font-size: 30px;
//             }

//             .blocks {
//                 grid-template-columns: 1fr;
//                 gap: 20px;

//                 img {
//                     height: auto;
//                 }
//             }
//         }
//     }
// }