.ourFeedback {
    width: 100%;

    .container {
        width: 100%;
        max-width: 1200px;
        padding: 40px 20px 80px;
        margin: auto;
        gap: 30px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            color: white;
            font-size: 40px;
            text-align: center;
            width: fit-content;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                bottom: -1px;
                left: 50%;
                width: 0%;
                height: 2px;
                background-color: gold;
                animation: anim 4s ease-in infinite;
            }

            @keyframes anim {
                0% {
                    left: 50%;
                }

                100% {
                    width: 100%;
                    left: 0;
                }
            }
        }

        .blocks {
            margin-top: 60px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            position: relative;

            .img2 {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -1;
                height: auto;
                animation: glass 4s ease-in-out infinite alternate;
                width: 100px;
                animation-delay: 1s;
            }

            .imgs {
                width: 100%;
                position: relative;

                .img1 {
                    width: 70%;
                    height: auto;
                    animation: glass 4s ease-in-out infinite alternate;
                }

                .img3 {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 35%;
                    height: auto;
                    animation: glass 4s ease-in-out infinite alternate;
                    animation-delay: 2s;
                }

            }

            @keyframes glass {
                0% {
                    transform: translateY(-10px);
                }

                100% {
                    transform: translateY(10px);
                }
            }

            .form {
                display: flex;
                gap: 30px;
                flex-direction: column;
                justify-content: center;
                max-width: 500px;

                .titInput {
                    color: white;
                    font-size: 20px;
                    text-decoration-line: underline;
                }

                input {
                    width: 100%;
                    height: 50px;
                    background-color: rgba(255, 255, 255, 0.4);
                    border-radius: 10px;
                    border: 2px solid white;
                    padding: 0 10px;
                    outline: none;
                    color: white;
                    font-size: 20px;

                    &::placeholder {
                        color: white;
                    }

                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                    -webkit-appearance: none; // Yeah, yeah everybody write about it
                }

                input[type='number'],
                input[type="number"]:hover,
                input[type="number"]:focus {
                    appearance: none;
                    -moz-appearance: textfield;
                }

                .btn {
                    width: fit-content;
                    height: 50px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    border: 0;
                    color: black;
                    transition: 0.5s;
                    font-size: 20px;
                    padding: 0 80px;
                    text-align: left;

                    &:hover {
                        cursor: pointer;
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }

    .police {
        font: 500 19px 'Oswald';
        background-color: white;
        padding: 5px 10px;
        text-align: center;
    }
}


@media (max-width: 768px) {
    .ourFeedback {
        width: 100%;

        .container {
            .title {
                font-size: 30px;
            }

            .blocks {
                grid-template-columns: 1fr;
                position: relative;
                width: 100%;
                height: 400px;

                .img2 {
                    height: auto;
                    width: 100px;
                }

                .imgs {
                    position: absolute;

                    .img1 {
                        width: 250px;
                        height: auto;
                    }

                    .img3 {
                        width: 150px;
                    }
                }

                .form {
                    max-width: 100%;
                    position: relative;
                    z-index: 10;
                    padding: 20px;
                    border-radius: 10px;
                    background-color: rgba(5, 5, 5, 0.4);
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .ourFeedback {
        width: 100%;

        .police {
            font: 500 15px 'Oswald';
            background-color: white;
            padding: 5px 10px;
            text-align: center;
        }

        .container {
            .title {
                font-size: 30px;
                padding-bottom: 10px;
            }

            .blocks {
                .img2 {
                    width: 100px;
                }

                .imgs {
                    .img1 {
                        width: 130px;
                    }

                    .img3 {
                        width: 80px;
                    }
                }

            }
        }
    }
}