.services {
    width: 100%;
    color: rgb(0, 0, 0);
    background-color: white;
    border-top: 8px solid gold;

    .container {
        width: 100%;
        max-width: 1200px;
        padding: 40px 20px 80px;
        margin: auto;
        gap: 30px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 40px;
            text-align: center;
            width: fit-content;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                bottom: -1px;
                left: 50%;
                width: 0%;
                height: 2px;
                background-color: gold;
                animation: anim 4s ease-in infinite;
            }

            @keyframes anim {
                0% {
                    left: 50%;
                }

                100% {
                    width: 100%;
                    left: 0;
                }
            }
        }

        .blocks {
            margin-top: 60px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 50px;

            .block {
                text-decoration: none;
                color: black;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;



                .anim {
                    width: 200px;
                    height: 200px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::before {
                        position: absolute;
                        content: "";
                        top: 0px;
                        left: 0;
                        width: 200px;
                        height: 200px;
                        background: conic-gradient(#fff 40deg, gold 0.55turn 0.6turn, #fff 1rad 1.5rad, gold 400grad);
                        border-radius: 50%;
                        animation: rotate 5s linear infinite;
                    }

                    @keyframes rotate {
                        0% {
                            transform: rotate(0deg);
                        }

                        100% {
                            transform: rotate(360deg);
                        }
                    }

                    .img {
                        position: absolute;
                        z-index: 20;
                        width: 190px;
                        height: 190px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }


                .titleText {
                    text-align: center;
                    font-size: 26px;
                    font-weight: 600;
                    border-bottom: 2px solid gold;
                }

                .descr {
                    text-align: center;
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .services {
        .container {
            .blocks {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .services {
        .container {
            .title {
                font-size: 40px;
            }

            .blocks {
                margin-top: 40px;
                grid-template-columns: 1fr;

                .block {
                    .anim {
                        width: 170px;
                        height: 170px;

                        &::before {
                            width: 170px;
                            height: 170px;
                        }

                        .img {
                            width: 160px;
                            height: 160px;
                        }
                    }


                    .titleText {
                        font-size: 22px;
                        padding-bottom: 10px;
                    }

                    .descr {
                        text-align: center;
                        font-size: 19px;
                    }
                }
            }
        }
    }
}