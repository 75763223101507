.whyMe {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    overflow: hidden;
    color: white;
    overflow: hidden;
    height: fit-content;

    .title {
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        font-size: 20px;
        font-weight: 500;
        background: rgba(255, 255, 255, 0.1);
    }

    .arrowBlock {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 20px 30px;

        .blockAccordion {
            font-size: 18px;
            transition: 200s ease-out;

            .desrc {
                display: flex;
                cursor: pointer;
                gap: 12px;
            }

            .descrBlock {
                margin-top: 10px;
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.1);
                padding: 8px 10px;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .whyMe {
        .title {
            padding-left: 20px;
            font-size: 19px;
        }

        .arrowBlock {
            padding: 20px;
            min-height: 240px;

            .blockAccordion {
                font-size: 17px;
            }
        }
    }
}