.client {
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-top: 8px solid gold;
    border-bottom: 8px solid gold;

    .container {
        width: 100%;
        max-width: 1200px;
        padding: 40px 20px 80px;
        margin: auto;
        gap: 30px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 40px;
            text-align: center;
            width: fit-content;
            position: relative;
            animation: anims 2s linear infinite alternate;

            @keyframes anims {
                0% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(1.2);
                }
            }

            &::after {
                position: absolute;
                content: "";
                bottom: -1px;
                left: 50%;
                width: 0%;
                height: 2px;
                background-color: gold;
                animation: anim 4s ease-in infinite;
            }

            @keyframes anim {
                0% {
                    left: 50%;
                }

                100% {
                    width: 100%;
                    left: 0;
                }
            }
        }

        .mySwiper {
            border-radius: 10px;
            height: auto;
            width: 100%;
            margin: auto;
            height: auto;
            padding: 20px 50px;


            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }

    .police {
        font: 500 19px 'Oswald';
        color: white;
        background-color: rgb(0, 0, 0);
        padding: 5px 10px;
        text-align: center;
    }
}




@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
    .client {
        .police {
            background-color: white;
            font: 500 15px 'Oswald';
            padding: 5px 10px;
            text-align: center;
        }

        .container {
            .title {
                font-size: 30px;
            }

            .mySwiper {
                padding: 20px 0;

                img {
                    width: 100%;
                    height: 500px;
                }
            }
        }
    }
}