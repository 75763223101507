@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Oswald', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
}

body::after {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100vh;
  z-index: -2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: 25s linear infinite running slidein;
}

body::before {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
}

@keyframes slidein {
  0% {
    background-image: url('./assets/img/bg1.jpg');
    transform: scale(1.2);
  }

  20% {
    background-image: url('./assets/img/bg1.jpg');
    transform: scale(1);
  }

  20.1% {
    background-image: url('./assets/img/bg.jpg');
    transform: scale(1);
  }

  40% {
    background-image: url('./assets/img/bg.jpg');
    transform: scale(1.2);
  }

  40.1% {
    background-image: url('./assets/img/bg2.jpg');
    transform: scale(1.2);
  }

  60% {
    background-image: url('./assets/img/bg2.jpg');
    transform: scale(1);
  }

  60.1% {
    background-image: url('./assets/img/b7.webp');
    transform: scale(1);

  }

  80% {
    background-image: url('./assets/img/b7.webp');
    transform: scale(1.2);
  }

  80.1% {
    background-image: url('./assets/img/bg3.webp');
    transform: scale(1.2);
  }

  100% {
    background-image: url('./assets/img/bg3.webp');
    transform: scale(1);
  }
}